.contact-me {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
}

.form-input,
.form-textarea {
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 1rem;
  border: 2px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.2s ease;
}

.form-input:focus,
.form-textarea:focus {
  outline: none;
  border-color: #4f46e5; /* Indigo-600 */
  box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.2);
}

.form-textarea {
  min-height: 120px;
  resize: vertical;
}

.form-button {
    width: 100%;
    padding: 1rem 2rem;
    background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgba(9,9,121,1) 100%);
    border: none;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    z-index: 1;
    box-shadow: 0 0 10px #6b5566;
  }

  .form-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 0 50px #6b5566;
  }
