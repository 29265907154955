.nav {
  position: fixed;
  width: 200px;
  height: 100vh;
  background-color: #222;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.circle-wrapper {
  position: fixed;
  width: 150px;
  height: 150px;
  left: 20px;
  top: 20px;
  border: 4px solid white;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


  .nav ul {
    list-style: none;
    padding: 0;
  }

  .nav li {
    margin-bottom: 1rem;
  }

  .nav button {
    width: 100%;
    padding: 1rem 2rem;
    background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgba(9,9,121,1) 100%);
    border: none;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    z-index: 1;
  }

  .nav button:hover {
    transform: translateY(-2px);
    box-shadow: 0 0 10px #6b5566;
  }

  .nav-button.active {
    background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgba(9,9,121,1) 100%);
    box-shadow: 0 0 25px #6b5566;
  }

  h3 {
    margin-bottom: 0.25rem;
  }

  h4 {
    margin-top: 0;
  }
