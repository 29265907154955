/* CSS file for the home screen component */

.image-slider {
    position: relative;
    width: 100vw;
    height: 200px;
    overflow: hidden;
  }

  .slide-image {
    position: absolute;
    height: 100%;
    left: 100%;
    width: auto;
    animation: slide 30s linear infinite;
    opacity: 0;
  }

  .slide-image:nth-child(1) {
    animation-delay: 0s;
  }
  .slide-image:nth-child(2) {
    animation-delay: 10s;
  }
  .slide-image:nth-child(3) {
    animation-delay: 20s;
  }

  @keyframes slide {
    0% {
      transform: translateX(0);
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    50% {
      transform: translateX(-100vw);
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      transform: translateX(-200vw);
      opacity: 0;
    }
  }
