.App {
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
}

.App-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  z-index: -10000;
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgba(9,9,121,1) 50%, rgb(0, 0, 0) 100%);
  background-size: 300% 300%;
  animation: gradientMove 25s ease infinite;

}

@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.App-content {
    position: relative;
    z-index: 1;
    min-height: 100vh;
    padding: 2rem;
}


@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-75px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-in-once {
  animation: slideInLeft 0.8s ease-out forwards;
}
